import React from 'react'
import Portfolio from '../Portfolio'
import Div from '../Div'
import Slider from 'react-slick';

export default function PortfolioSlider() {
  const portfolioData = [
    {
      title: 'IoT Hub El Salvador',
      subtitle: 'See Details',
      href: 'https://ioth-ub-sv.vercel.app/',
      src: '/images/iothub_page.png'
    },
    {
      title: 'Corporate Website: DMA',
      subtitle: 'See Details',
      href: '/',
      src: '/images/dma_website.png'
    },
    {
      title: 'Wannatrip - Turism Tech App',
      subtitle: 'See Details',
      href: 'https://www.wannatrip.sv/',
      src: '/images/wannatrip_website.png'
    },
    {
      title: 'Finance Tracking Chatbot',
      subtitle: 'See Details',
      href: '/',
      src: '/images/chatbotdma_page.png'
    },
    {
      title: 'Geospatial data manipulation',
      subtitle: 'See Details',
      href: '/',
      src: '/images/geospatial.png'
    },
    {
      title: 'Landing page - Eduardo Tutor',
      subtitle: 'See Details',
      href: 'https://www.eduardotutor.com/',
      src: '/images/eduardo_landing.png'
    },
    {
      title: 'Landing page - Nexycards',
      subtitle: 'See Details',
      href: 'https://www.nexycards.com/',
      src: '/images/nexy_landing.png'
    }
  ]

  /** Slider Settings **/
  const settings = {
    className: "center",
    centerMode: true,
    infinite: true,
    centerPadding: "0",
    slidesToShow: 3,
    speed: 500,
    dots: true,
    arrows: false,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1
        }
      }
    ]
  };

  return (
    <Slider {...settings} className='cs-slider cs-style3 cs-gap-24'>
      {portfolioData.map((item, index) => (
        <Div key={index}>
          <Portfolio
            title={item.title}
            subtitle={item.subtitle}
            href={item.href}
            src={item.src}
          />
        </Div>
      ))}
    </Slider>
  )
}
