import React from 'react'
import Div from '../Div'
import './logolist.scss'

export default function LogoList() {
  const partnerLogos = [
    {
      src: '/images/aws.svg',
      alt: 'Partner'
    },
    {
      src: '/images/bi.svg',
      alt: 'Partner'
    },
    {
      src: '/images/python-logo.svg',
      alt: 'Partner'
    },
    {
      src: '/images/react.svg',
      alt: 'Partner'
    },
    {
      src: '/images/tensor.svg',
      alt: 'Partner'
    },

  ]
  return (
    <Div className="cs-partner_logo_wrap">
      {partnerLogos.map((partnerLogo, index) => <div className="cs-partner_logo" key={index}><img src={partnerLogo.src} alt={partnerLogo.alt} width={100} height={100} /></div>)}
    </Div>
  )
}
