import React from 'react'
import { Link } from 'react-router-dom'
import { Icon } from '@iconify/react';
import Div from '../Div';

export default function SocialWidget() {
  return (
    <Div className="cs-social_btns cs-style1">
      <a href='https://www.linkedin.com/company/outsidetechco/' className="cs-center">
        <Icon icon="fa6-brands:linkedin-in" />
      </a>
      <a href='https://www.instagram.com/outsidetechco/' className="cs-center">
        <Icon icon="fa6-brands:instagram" />
      </a>
      <a href='https://www.facebook.com/outsidetechco' className="cs-center">
        <Icon icon="fa6-brands:facebook" />
      </a>
    </Div>
  )
}
